import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import theme from "../ui/theme";

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  @media all and (max-width: ${theme.breakpoints.md}) {
    justify-content: space-between;
  }

  .pagination {
    &__link,
    &__number,
    &__ellipsis {
      margin: 0 5px;
      border-style: solid;
      border-width: 1px;
      color: #081a31;
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      min-width: 40px;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      transition: all 0.2s ease-out;
      white-space: nowrap;
      &[aria-current] {
        background-color: #ffc71b;
        border-color: #ffc71b;
      }
    }
    &__link {
      margin: 0;
      max-height: 36px;
      &.disabled {
        opacity: 0.2;
      }
      @media all and (min-width: 641px) {
        font-size: 11px;
        line-height: 1.25;
        min-width: 94px;
        text-transform: uppercase;
      }
    }
  }
  a:hover {
    background-color: #081a31;
    border-color: #081a31;
    color: #fff;
  }
`;

const Pagination = ({ context }) => {
  const { index, pageCount, pathPrefix } = context;
  const firstPage = index === 1;
  const lastPage = index === pageCount;
  const endIsNear = index >= pageCount - 4; // Returns true when current page is within the last 4
  const mobileEndIsNear = index >= pageCount - 2; // Returns true when current page is within the last 2
  const mobile = typeof window !== "undefined" && window.innerWidth < 640;

  // Build out the pagination links
  const PageLink = (props) => {
    if (!props.check) {
      let url =
        props.page === 1 ? `/${pathPrefix}/` : `/${pathPrefix}/${props.page}/`;
      return (
        <Link to={url} className="pagination__link">
          {props.text}
        </Link>
      );
    } else {
      return <span className="pagination__link disabled">{props.text}</span>;
    }
  };

  // Build out the pagination numbers
  const PageNumber = (props) => {
    let url =
      props.page === 1 ? `/${pathPrefix}/` : `/${pathPrefix}/${props.page}/`;
    return (
      <Link to={url} className="pagination__number">
        {props.page}
      </Link>
    );
  };

  return (
    pageCount > 1 && (
      <PaginationWrapper className="pagination">
        {mobile ? (
          <>
            <PageLink
              check={firstPage}
              page={index - 1}
              text={<FaArrowLeft />}
            />
            <div className="pagination__numbers">
              {pageCount > 2 ? (
                <>
                  {mobileEndIsNear && <PageNumber page={1} />}
                  <PageNumber page={mobileEndIsNear ? pageCount - 2 : index} />
                  <PageNumber
                    page={mobileEndIsNear ? pageCount - 1 : index + 1}
                  />
                  {!mobileEndIsNear && (
                    <span className="pagination__ellipsis">...</span>
                  )}
                  <PageNumber page={pageCount} />
                </>
              ) : (
                <>
                  <PageNumber page={pageCount - 1} />
                  <PageNumber page={pageCount} />
                </>
              )}
            </div>
            <PageLink
              check={lastPage}
              page={index + 1}
              text={<FaArrowRight />}
            />
          </>
        ) : (
          <>
            <PageLink check={firstPage} page={index - 1} text="Previous" />
            <div className="pagination__numbers">
              {pageCount > 4 ? (
                <>
                  {!firstPage && <PageNumber page={1} />}
                  {index > 2 && (
                    <span className="pagination__ellipsis">...</span>
                  )}
                  <PageNumber page={endIsNear ? pageCount - 4 : index} />
                  <PageNumber page={endIsNear ? pageCount - 3 : index + 1} />
                  <PageNumber page={endIsNear ? pageCount - 2 : index + 2} />
                  <PageNumber page={endIsNear ? pageCount - 1 : index + 3} />
                  {!endIsNear && (
                    <span className="pagination__ellipsis">...</span>
                  )}
                  <PageNumber page={pageCount} />
                </>
              ) : (
                <>
                  <PageNumber page={pageCount - pageCount + 1} />
                  <PageNumber page={pageCount - pageCount + 2} />
                  {pageCount > 2 && (
                    <PageNumber page={pageCount - pageCount + 3} />
                  )}
                  {pageCount > 3 && <PageNumber page={pageCount} />}
                </>
              )}
            </div>
            <PageLink check={lastPage} page={index + 1} text="Next" />
          </>
        )}
      </PaginationWrapper>
    )
  );
};

export default Pagination;
