import React from "react";
import Layout from "../components/Layout";
import PostGrid from "../ui/PostGrid";
import PostGridItem from "../components/PostGridItem";
import Seo from "../components/Seo";
import Pagination from "../components/Pagination";
import { useStaticQuery, graphql } from "gatsby";
import get from "lodash/get";

const Blog = ({ pageContext }) => {
  const { group } = pageContext;
  const { placeholderImage, wordpressPage, firstArticle, site } =
    useStaticQuery(graphql`
      query BlogLandingQuery {
        site {
          siteMetadata {
            baseUrl
          }
        }
        wordpressPage(wordpress_id: { eq: 437 }) {
          date
          modified
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
        }
        placeholderImage: file(relativePath: { eq: "default.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        firstArticle: wordpressPost {
          slug
          title
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 980) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `);

  return (
    <Layout templateName={`blog page-${pageContext.index}`}>
      <Seo
        title={wordpressPage.yoast_meta.yoast_wpseo_title}
        description={wordpressPage.yoast_meta.yoast_wpseo_metadesc}
        image={
          wordpressPage.featured_media && wordpressPage.featured_media.localFile
            ? `${
                process.env.NODE_ENV === "production"
                  ? site.siteMetadata.baseUrl
                  : typeof window !== "undefined" && window.location.host
              }${
                wordpressPage.featured_media.localFile.childImageSharp.original
                  .src
              }`
            : ""
        }
        canonical={wordpressPage.yoast_meta.yoast_wpseo_canonical}
        schemaType="WebPage"
        datePublished={wordpressPage.date}
        dateModified={wordpressPage.modified}
      />
      <PostGrid>
        {pageContext.index === 1 && (
          <PostGridItem
            key={firstArticle.slug}
            slug={firstArticle.slug}
            title={firstArticle.title}
            fluidImage={
              firstArticle.featured_media
                ? firstArticle.featured_media.localFile.childImageSharp.fluid
                : placeholderImage.childImageSharp.fluid
            }
          />
        )}
        {group.map(({ node }) => {
          return (
            <PostGridItem
              key={node.slug}
              slug={node.slug}
              title={node.title}
              fluidImage={get(
                node,
                "featured_media.localFile.childImageSharp.fluid",
                placeholderImage.childImageSharp.fluid,
              )}
              date={node.date}
              content={node.content}
            />
          );
        })}
      </PostGrid>
      <Pagination context={pageContext} />
    </Layout>
  );
};

export default Blog;
